//@import url("https://use.typekit.net/qif4fcq.css");
@import url("https://use.typekit.net/nsk2yda.css");

@import "~antd/dist/antd.less";

img {
  display: inline-block;
}

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.anticon svg {
  display: block;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-menum.ant-menu-horizontal {
  justify-content: center !important;
  margin-left: -15px;
  margin-right: -15px;
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
  background-color: transparent;
}
.ant-descriptions-title {
  color: #35adef;
}
.ant-descriptions-view {
  border: 1px solid #ddd !important;
  margin-top: -15px;
}
// .mobil-sider {
//   position: absolute;
//   z-index: 1;
//   min-height: 100%;
//   border-right: 1px solid #ddd;
//   background-color: #f5f7f9;
// }
.ant-layout-content {
  background-color: #f5f5f5 !important;
  background-repeat: repeat;
  min-height: calc(100vh - 188px);
}
.ant-layout-header {
  height: unset !important;
  background-color: white;
  padding: 0 15px !important;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
}
.amenu {
  background-color: transparent;
  margin-top: 10px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-overflow-item.ant-menu-item {
  padding: 0 15px !important;
  text-transform: uppercase;
  font-size: 15px;
  color: #003975;
}

.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 0 0;
}

.ant-image-preview-img {
  display: initial !important;
}

.bg-white {
  background-color: white !important;
}

.ic {
  color: white;
  position: relative;
  display: inline-block;

  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}
.layer {
  background-color: rgba(22, 22, 22, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hakkinda ul {
  list-style: unset;
  padding: 8px 30px;
}
.hakkinda ol {
  list-style-type: decimal;
  padding: 8px 30px;
}
.hakkinda ul li {
  list-style: disc;
}
.imaj {
  border: 3px solid white;
  box-shadow: 0 0 5px #ddd;
}
.ant-menu-overflow-item {
  font-weight: 600;
}
.ql-editor {
  min-height: 250px;
  background-color: white;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: @red-5;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: @red-7;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.timeline-Header.timeline-InformationCircle-widgetParent {
  border: 1px solid #eee !important;
  background-color: red !important;
}

.stats-div {
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  padding: 20px;
  background-color: white !important;
}
.stat-card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  width: 100%;
}
.ant-table-thead > tr > th {
  color: @blue-6!important;
}
.Demo__some-network {
  display: inline;
  margin-right: 3px;
}
.react-share__ShareButton svg {
  border-radius: 3px !important;
}
.ql-video {
  width: 100%;
  height: 400px;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 15px 0;
}

.ant-menu-item {
  margin-bottom: 0 !important;
}
.cerceve {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ccc;

  box-shadow: 0 0 15px #ddd;
  background-color: white !important;
}
.ant-card-meta-description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #003975;
}
.stat-card {
  border: 1px solid #ccc;
  box-shadow: 0 0 10px #eee;
  width: 100%;
}
.loader-bg {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #252427;
}
.loader-bg > img {
  margin-top: -100px;
  margin-left: -20px;
  position: absolute;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@primary-color: #28ABE2;@font-family: foco, "ff-dax-pro",ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";